import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Turkish Get Ups & Skin the Cat`}</p>
    <p>{`then, 20:00 AMRAP of:`}</p>
    <p>{`15 Calorie Assault Bike`}</p>
    <p>{`30 Pushups`}</p>
    <p>{`15 Ring Rows`}</p>
    <p>{`30 Double Unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`This Saturday is our free Partner Workout at 9:00 and 10:15am so
bring a friend. If you aren’t a member but would like to attend please
email Daniel\\@crossfittheville.org.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      